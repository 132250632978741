import React from 'react';
import { useNavigate } from 'react-router-dom';
import s from './s.module.less';
import { TProfileData } from 'types/home';

type IProps = {
    profileData: TProfileData;
};

const Index: React.FC<IProps> = ({ profileData }) => {
    const navigate = useNavigate();
    if (!profileData.notCompetitiveServicePer) {
        return null;
    }
    return (
        <div className={s.wrap}>
            <p className={s.description}>
                {profileData.notCompetitiveServicePer}% of your services are not
                competitively priced and we suggest price review.
            </p>
            <div
                className={s.applyButton}
                onClick={() => {
                    navigate('/create-uni-profile?type=uniprofile&from=home');
                }}
            >
                Get pricing insights
            </div>
        </div>
    );
};

export default Index;
