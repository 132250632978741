import React, { useRef, useState, useEffect } from 'react';
import { message, Spin, Tour } from 'antd';
import useProviderHomeInfo from 'hooks/useProviderHomeInfo';
import useHomeConfig from 'hooks/useHomeConfig';
import PaymentStatus from 'components/PaymentStatus';
import AgreementStatus from '../AgreementStatus';
import SubscriptionStore from 'store/Subscription';
import { isFreeUserByInfo } from 'utils/provider';
import { CouponType, Provider } from 'types/provider';
import type { TProfileData } from 'types/home';
import Summary from '../Summary';
import TopOpportunities from '../TopOpportunities';
import GrowYourBusiness from '../GrowYourBusiness';
import QuickActions from '../QuickActions';
import ProviderStore from 'store/Provider';
import type { TourProps } from 'antd';
import { getQuickAction } from 'api/home';
import s from './s.module.less';

const Index: React.FC = () => {
    const [data, loadingHome, fetchData] = useProviderHomeInfo();
    const [get] = ProviderStore.useStore();
    const provider: Provider = get('data') as Provider;
    const [configList, loadingConfig] = useHomeConfig();
    const [, contextHolder] = message.useMessage();
    const payPlan = SubscriptionStore.get('currentPlan');
    const isFreeUser = payPlan && isFreeUserByInfo(payPlan);
    const agreementReviewTypeList = data?.agreementReviewTypeList;
    const isKlarity = !!data?.klarityUser;

    const spinning = loadingHome || loadingConfig;
    const [profileData, setProfileData] = useState<TProfileData>(
        {} as TProfileData,
    );

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);

    const [open, setOpen] = useState<boolean>(false);

    const getGreeting = () => {
        const currentHour = new Date().getHours();
        let greeting = '';
        if (currentHour >= 5 && currentHour < 12) {
            greeting = 'Good morning';
        } else if (currentHour >= 12 && currentHour < 17) {
            greeting = 'Good afternoon';
        } else if (currentHour >= 17 && currentHour < 21) {
            greeting = 'Good evening';
        } else {
            greeting = 'Good night';
        }

        return greeting;
    };

    const steps: TourProps['steps'] = [
        {
            title: ' ',
            description:
          'Track key practice metrics and assess progress. Use top-right filters to analyze trends over specific periods.',
            target: () => ref1.current,
        },
        {
            title: ' ',
            description:
          'Your guide to practice growth. Discover strategies to expand your patient base, enhance experience, and diversify services.',
            target: () => ref2.current,
        },
        {
            title: ' ',
            description:
          'Your practice growth roadmap. Track progress on key objectives with helpful tips and resources.',
            target: () => ref3.current,
        },
        {
            title: ' ',
            description:
          'Quick access to frequently used tools and resources, streamlining workflow and saving time.',
            target: () => ref4.current,
        },
        {
            title: ' ',
            description:
          'Share your Google profile to existing patients to earn 5-star rating.',
            target: () => ref4.current,
        },
        {
            title: ' ',
            description:
          'Share your Practice Front to allow patients to find out your full services',
            target: () => ref4.current,
        },
    ];

    const getData = async () => {
        const result = await getQuickAction();
        setProfileData(result.data.data);
    };

    useEffect(() => {
        getData();
        if (!localStorage.getItem('HOME_TOUR_ISOPEN')) {
            localStorage.setItem('HOME_TOUR_ISOPEN', true);
            setOpen(true);
        }
    }, []);

    return (
        <Spin tip="Loading" size="large" spinning={spinning}>
            <div className={s.wrapper}>
                <div className={s.inner}>
                    {contextHolder}
                    <div className={s.header}>
                        <div className={s.title}>
                            {getGreeting()}
                            {provider?.firstName ? `, ${provider?.firstName}` : ''} 👋🏻
                        </div>
                    </div>
                    <PaymentStatus />
                    {payPlan?.couponType !== CouponType.HEADWAY &&
              agreementReviewTypeList &&
              agreementReviewTypeList.length > 0 && (
              <AgreementStatus fetchData={fetchData} />
                    )}
                    <div className={s.content}>
                        <div className={s.left}>
                            <div ref={ref1}>
                                <Summary isKlarity={isKlarity} profileData={profileData} />
                            </div>
                            <div ref={ref2}>
                                <TopOpportunities isKlarity={isKlarity} />
                            </div>
                        </div>
                        <div className={s.right}>
                            <div ref={ref3}>
                                <GrowYourBusiness isKlarity={isKlarity} />
                            </div>
                            <div ref={ref4}>
                                <QuickActions profileData={profileData} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Tour
                open={open}
                onClose={() => setOpen(false)}
                steps={steps}
                indicatorsRender={(current, total) => (
                    <span>
                        Step {current + 1} out of {total}
                    </span>
                )}
            />
        </Spin>
    );
};

export default Index;
